import clsx from "clsx";
import { PropsWithChildren } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import styles from "./BackButton.module.css";
import Button from "./Button";
import ArrowLeft from "./icon/arrow-left.svg?react";
import Close from "./icon/close.svg?react";

type BackButtonProps = PropsWithChildren<{
  onClick: () => void;
  title?: string;
  className?: string;
}>;

export const BackButton = ({ onClick, title, className }: BackButtonProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Button
      layout="link"
      buttonProps={{
        onClick,
        title: title ?? i18n.general.back,
        "aria-label": title ?? i18n.general.back,
        className: clsx(className, styles.button),
      }}
    >
      <span className={styles.text}>
        <ArrowLeft aria-hidden className={styles.arrow} />
        {title ?? i18n.general.back}
      </span>
      <span className={styles.iconWrapper}>
        <Close className={styles.icon} />
      </span>
    </Button>
  );
};
