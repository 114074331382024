import { Guest, PrepaymentType } from "../../http/bookingApi";

export enum CheckoutStep {
  Summary = 1,
  Form = 2,
}

export type CheckoutForm = Guest & {
  insurance: boolean;
  voucher_code?: string;
  prepayment_type: PrepaymentType;
  guestType: GuestType;
  privacyPolicy: boolean;
};

export enum GuestType {
  Private = "private",
  Company = "company",
}
