import { Language } from "../../@types";
import HGVLogo from "../../assets/hgv.svg?react";
import { useBookingStore } from "../../hooks/useBookingStore";
import useToggle from "../../hooks/useToggle";
import { useLegalTexts } from "../../http/legalTextsApi";
import { translate } from "../../i18n";
import Header from "../../layouts/Header";
import Button from "../../ui/Button";
import ButtonGroup from "../../ui/ButtonGroup";
import Modal from "../../ui/Modal";
import ResponsiveImage from "../../ui/ResponsiveImage";
import Select from "../../ui/Select";
import { getImagesByOrientation } from "../../utils/image";
import { getLanguageByCode } from "../../utils/language";
import { site } from "../../utils/site";
import styles from "./Start.module.css";

const Start = () => {
  const language = useBookingStore((state) => state.language);
  const setLanguage = useBookingStore((state) => state.setLanguage);
  const i18n = translate(language);
  const privacyModal = useToggle();
  const termsModal = useToggle();
  const { data: legalTexts } = useLegalTexts(
    privacyModal.isOpen || termsModal.isOpen,
  );
  const bgImage = getImagesByOrientation(site.background_image.derivatives);
  const bgDescription = site.background_image.description[language];

  return (
    <div className={styles.start}>
      <Header hideOnDesktop />
      <ResponsiveImage
        className={styles.backgroundImage}
        alt={bgDescription}
        sizes="100vw"
        title={bgDescription}
        srcSet={bgImage.landscape}
        srcSetPortrait={bgImage.portrait}
      />
      <img
        className={styles.logo}
        alt={site.property_name}
        title={site.property_name}
        src={site.logo.url}
        width={site.logo.width}
        height={site.logo.height}
      />
      <ButtonGroup className={styles.buttonGroup} />
      <div className={styles.linksWrapper}>
        <div className={styles.links}>
          <Button
            buttonProps={{
              title: i18n.start.privacy,
              onClick: () => privacyModal.open(),
              className: styles.link,
            }}
            layout="link"
          >
            {i18n.start.privacy}
          </Button>
          <Button
            buttonProps={{
              title: i18n.start.termsAndConditions,
              onClick: () => termsModal.open(),
              className: styles.link,
            }}
            layout="link"
          >
            {i18n.start.termsAndConditions}
          </Button>
          <Select
            name="language"
            value={language}
            options={Object.values(Language).map((value) => ({
              key: value,
              value,
            }))}
            onChange={(e) => setLanguage(getLanguageByCode(e.target.value))}
          />
        </div>
        <HGVLogo className={styles.hgvLogo} title={i18n.start.hgv} role="img" />
      </div>
      {/*
        TODO: Implement skeleton loading for better UX
        instead of opening when loaded.
      */}
      {privacyModal.isOpen && legalTexts && (
        <Modal
          title={i18n.start.privacy}
          description={legalTexts.privacy}
          isOpen={privacyModal.isOpen}
          onClose={privacyModal.close}
        />
      )}
      {termsModal.isOpen && legalTexts && (
        <Modal
          title={i18n.start.termsAndConditions}
          description={legalTexts.terms}
          isOpen={termsModal.isOpen}
          onClose={termsModal.close}
        />
      )}
    </div>
  );
};

export default Start;
