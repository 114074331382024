import { useState } from "react";

interface UseToggleProps {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  toggle: () => void;
}

const useToggle = (): UseToggleProps => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    close: () => setIsOpen(false),
    open: () => setIsOpen(true),
    toggle: () => setIsOpen((isOpen) => !isOpen),
  };
};

export default useToggle;
