import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import { BackButton } from "./BackButton";
import CenterWrapper from "./CenterWrapper";
import styles from "./FullscreenModal.module.css";
import Headline from "./Headline";

interface FullscreenModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  content?: ReactNode;
  shareView?: boolean;
}

const FullscreenModal = ({
  isOpen,
  title,
  content,
  onClose,
  shareView,
}: FullscreenModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className={styles.modal}>
      <DialogPanel
        className={clsx(styles.dialog, {
          [styles.shareView]: shareView,
        })}
      >
        <div className={styles.innerDialog}>
          <DialogTitle as="div" className={styles.header}>
            <Headline
              size={2}
              title={title}
              as="div"
              className={styles.headerTitle}
            />
            <BackButton onClick={onClose} />
          </DialogTitle>
          <div className={styles.dialogContent}>
            <Headline
              size={2}
              title={title}
              as="h1"
              className={styles.headline}
            />
            <CenterWrapper className={styles.content}>{content}</CenterWrapper>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default FullscreenModal;
