import { MonthCaption, MonthCaptionProps } from "react-day-picker";
import styles from "./CalendarMonthCaption.module.css";
import { useCalenderContext } from "./utils";

const CalendarMonthCaption = ({
  calendarMonth,
  displayIndex,
  ...rest
}: MonthCaptionProps) => {
  const { updateMonthRef } = useCalenderContext();

  return (
    <div
      ref={(ref) => updateMonthRef(ref, calendarMonth, displayIndex)}
      className={styles.captionWrapper}
    >
      <MonthCaption
        calendarMonth={calendarMonth}
        displayIndex={displayIndex}
        {...rest}
      />
    </div>
  );
};

export default CalendarMonthCaption;
