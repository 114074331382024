import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import styles from "./Extras.module.css";

const Extras = () => {
  const language = useBookingStore((state) => state.language);
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );
  const setStep = useBookingStoreSetStep();
  const i18n = translate(language);

  return (
    <PageWithHeader
      header={<Headline as="h1" size={2} title={i18n.extras.selectExtras} />}
    >
      <CenterWrapper>
        <div className={styles.extras}>Extras</div>
      </CenterWrapper>
      <div className={styles.buttonWrapper}>
        <CenterWrapper className={styles.buttonCenterWrapper}>
          <Button
            layout="primary"
            buttonProps={{
              title: i18n.extras.goToCheckout,
              className: styles.continueButton,
              onClick: () => {
                setStep(BookingStep.Checkout);
                updateCheckoutData();
              },
            }}
          >
            {i18n.extras.goToCheckout}
          </Button>
        </CenterWrapper>
      </div>
    </PageWithHeader>
  );
};

export default Extras;
