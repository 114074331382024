import { ReactNode } from "react";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  Mode,
  SubmitHandler,
  UseFormReturn,
  useForm,
} from "react-hook-form";

type FormChildrenProps<TFieldValues extends FieldValues> =
  UseFormReturn<TFieldValues> & {
    disabled?: boolean;
  };

interface FormProps<TFieldValues extends FieldValues> {
  onSubmit?: SubmitHandler<TFieldValues>;
  defaultValues: DefaultValues<TFieldValues>;
  children?:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
  className?: string;
  mode?: Mode;
  reValidateMode?: Exclude<Mode, "onTouched" | "all">;
}

const Form = <TFieldValues extends FieldValues>({
  onSubmit,
  defaultValues,
  children,
  className,
  mode = "all",
  reValidateMode = "onChange",
}: FormProps<TFieldValues>) => {
  const useFormProps = useForm<TFieldValues>({
    defaultValues,
    mode,
    reValidateMode,
  });

  return (
    <FormProvider {...useFormProps}>
      <form
        className={className}
        onSubmit={(e) => {
          if (onSubmit) {
            e.stopPropagation();
            useFormProps.handleSubmit(onSubmit)(e);
          }
        }}
      >
        {typeof children === "function"
          ? children({ ...useFormProps })
          : children}
      </form>
    </FormProvider>
  );
};

export default Form;
