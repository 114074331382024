import { useState } from "react";
import { WretchError } from "wretch/resolver";
import { useBookingStore } from "../../hooks/useBookingStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import Error, { ErrorType } from "../../pages/Error";
import PageWithHeader from "../../pages/PageWithHeader";
import CenterWrapper from "../../ui/CenterWrapper";
import styles from "./Checkout.module.css";
import CheckoutForm from "./CheckoutForm";
import CheckoutSummary from "./CheckoutSummary";
import { CheckoutStep } from "./utils";

const Checkout = () => {
  const isDesktop = useMediaQuery("(min-width: 1126px)");
  const [checkoutStep, setCheckoutStep] = useState(CheckoutStep.Summary);
  const checkoutData = useBookingStore((state) => state.checkoutData);
  const checkoutDataError = useBookingStore((state) => state.checkoutDataError);
  const isUpdatingCheckoutData = useBookingStore(
    (state) => state.isUpdatingCheckoutData,
  );
  const [isBookable, setIsBookable] = useState(true);

  if (!isUpdatingCheckoutData && checkoutDataError) {
    if (checkoutDataError instanceof WretchError) {
      if (checkoutDataError.status === 409) {
        setIsBookable(false);
      }

      // TODO: handle other status codes -> default error boundary?
    }

    // TODO: default error boundary
    throw checkoutDataError;
  }

  if (!isBookable) {
    return <Error type={ErrorType.Warning} />;
  }

  return (
    <PageWithHeader contentClassName={styles.pageContent}>
      <CenterWrapper className={styles.centerWrapper}>
        {(checkoutStep === CheckoutStep.Summary || isDesktop) && (
          <div className={styles.checkoutSummaryWrapper}>
            <CheckoutSummary onChangeStep={(step) => setCheckoutStep(step)} />
          </div>
        )}
        {(checkoutStep === CheckoutStep.Form || isDesktop) && (
          <CheckoutForm checkoutData={checkoutData} />
        )}
      </CenterWrapper>
    </PageWithHeader>
  );
};

export default Checkout;
