import { Field, Checkbox as HeadlessCheckbox, Label } from "@headlessui/react";
import { forwardRef, useId } from "react";
import { FieldError } from "react-hook-form";
import { sanitize } from "../utils/string";
import styles from "./Checkbox.module.css";
import FormError from "./FormError";
import Check from "./icon/check.svg?react";

interface CheckboxProps {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  error?: FieldError | undefined;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label, className, error, required, ...rest }, ref) => {
    const errorId = useId();

    return (
      <Field className={className}>
        <div className={styles.checkboxWrapper}>
          <HeadlessCheckbox
            ref={ref}
            className={styles.checkbox}
            aria-invalid={!!error}
            aria-required={required}
            aria-errormessage={error ? errorId : undefined}
            name={name}
            {...rest}
          >
            <Check className={styles.icon} aria-hidden />
          </HeadlessCheckbox>
          {label && (
            <Label
              className={styles.label}
              dangerouslySetInnerHTML={{
                __html: sanitize(label + (required ? "*" : "")),
              }}
            />
          )}
        </div>
        <FormError id={errorId} errorMessage={error?.message ?? ""} />
      </Field>
    );
  },
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
