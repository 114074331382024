import clsx from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./Slider.module.css";
import { NextButton, PrevButton, usePrevNextButtons } from "./SliderArrows";
import { DotButton, useDotButton } from "./SliderDots";

type SliderProps = PropsWithChildren<{
  arrows?: boolean;
  dots?: boolean;
  loop?: boolean;
  className?: string;
  customControls?: ReactNode;
}>;

const Slider = ({
  arrows = false,
  dots = true,
  loop = true,
  className,
  children,
  customControls,
}: SliderProps) => {
  const [emblaRef, emblaAPI] = useEmblaCarousel({ loop });
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaAPI);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaAPI);

  return (
    <div className={clsx(styles.sliderWrapper, className)}>
      <div className={styles.sliderContainer} ref={emblaRef}>
        <div className={styles.slider}>{children}</div>
      </div>
      {arrows && (
        <>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </>
      )}
      {dots && (
        <div className={styles.dots}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              active={index === selectedIndex}
            />
          ))}
        </div>
      )}
      {customControls && customControls}
    </div>
  );
};

export default Slider;
