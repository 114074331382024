import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { Photo } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "./Button";
import styles from "./LightGallery.module.css";
import ResponsiveImage from "./ResponsiveImage";
import Close from "./icon/close.svg?react";

interface LightGalleryProps {
  photos: Photo[];
  isOpen: boolean;
  onClose: () => void;
}

const LightGallery = ({ photos, isOpen, onClose }: LightGalleryProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Dialog open={isOpen} onClose={onClose} className={styles.overlay}>
      <DialogPanel className={styles.dialog}>
        <div className={styles.inner}>
          <DialogTitle as="div" className={styles.header}>
            <Button
              layout="link"
              buttonProps={{
                onClick: onClose,
                "aria-label": i18n.general.close,
                className: styles.closeButton,
              }}
            >
              <Close className={styles.icon} />
            </Button>
          </DialogTitle>
          <div className={styles.photosWrapper}>
            <div className={styles.photos}>
              {photos.map((photo, index) => (
                <ResponsiveImage
                  key={index}
                  className={styles.photo}
                  alt={photo.description}
                  title={photo.description}
                  srcSet={photo.derivatives}
                  sizes="100vw"
                  lazyLoad
                />
              ))}
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default LightGallery;
