import { startOfDay } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { DayButtonProps } from "react-day-picker";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Tooltip from "../../ui/Tooltip";
import CalendarDayContent from "./CalendarDayContent";
import {
  InvalidDayReasonType,
  isDayInvalid,
  useCalenderContext,
} from "./utils";

const CalendarDayButton = ({
  day,
  modifiers,
  ...buttonProps
}: DayButtonProps) => {
  const now = useMemo(() => new Date(), []);
  const today = useMemo(() => startOfDay(now), [now]);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { invalid, departure: isDeparture, focused } = modifiers;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const calendarContext = useCalenderContext();

  useEffect(() => {
    if (focused) buttonRef.current?.focus();
  }, [focused]);

  const {
    arrival,
    arrivalDays,
    departure,
    departureDays,
    maxDepartureDate,
    maxStay,
    minDepartureDate,
    minStay,
  } = calendarContext;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (invalid || (minStay > 0 && !isDeparture)) {
      setTooltipVisible(true);
    }

    if (invalid) {
      return;
    }

    buttonProps.onClick?.(e);
  };

  const invalidReason = isDayInvalid(day.date, {
    today,
    arrival,
    arrivalDays,
    departure,
    departureDays,
    maxDepartureDate,
    maxStay,
    minDepartureDate,
    minStay,
  });

  const tooltipTitle = () => {
    if (invalidReason) {
      switch (invalidReason.type) {
        case InvalidDayReasonType.ArrivalNotAvailable:
          return i18n.calendar.arrivalNotAvailable;
        case InvalidDayReasonType.MinStay:
          return i18n.calendar.minStay(minStay);
        case InvalidDayReasonType.MaxStay:
          return i18n.calendar.maxStay(maxStay);
        case InvalidDayReasonType.DepartureNotAvailable:
          return i18n.calendar.departureNotAvailable;
        case InvalidDayReasonType.DepartureBeforeArrival:
          return i18n.calendar.departureBeforeArrival;
        default:
          return "";
      }
    }

    if (minStay > 0 && departureDays.length) {
      return i18n.calendar.minStay(minStay);
    }

    return "";
  };

  return (
    <>
      <button
        type="button"
        {...buttonProps}
        ref={buttonRef}
        onClick={handleClick}
      >
        <CalendarDayContent day={day} />
      </button>
      <Tooltip
        isVisible={tooltipVisible}
        anchor={buttonRef.current}
        title={tooltipTitle()}
        onClose={() => setTooltipVisible(false)}
        onIsPositionChange={(floating) => {
          floating.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
          });
        }}
      />
    </>
  );
};

export default CalendarDayButton;
