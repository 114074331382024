import { useBookingStore } from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { Room } from "../../http/roomApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import { site } from "../../utils/site";
import AmenityIcons from "./AmenityIcons";
import styles from "./FavoredAmenities.module.css";

interface FavoredAmenitiesProps {
  room: Room;
  shareView?: boolean;
}

const FavoredAmenities = ({
  room,
  shareView = false,
}: FavoredAmenitiesProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const modalIdentifier = `FavoredAmenities_${room.id}`;
  const toggleAmenitiesModal = useModalHistoryToggle(modalIdentifier);
  const showAmenitiesModal = useModalHistoryStore(
    isModalActive(modalIdentifier),
  );

  if (!room.amenities.length) {
    return null;
  }

  return (
    <div className={styles.favoredAmenities}>
      <Headline size={5} title={i18n.rooms.amenities(site.room_term)} />
      <Headline
        size={4}
        title={room.description}
        className={styles.description}
      />
      <ul className={styles.amenities}>
        {room.amenities
          .filter((amenity) => amenity.is_favored)
          .map((amenity) => {
            const AmenityIcon = AmenityIcons[amenity.id];
            return (
              <li key={amenity.id} className={styles.amenity}>
                {AmenityIcon && (
                  <AmenityIcon aria-hidden className={styles.amenityIcon} />
                )}
                {amenity.name}
              </li>
            );
          })}
      </ul>
      <div className={styles.showMore}>
        <Button
          layout="link"
          buttonProps={{
            className: styles.showMoreButton,
            title: i18n.rooms.showMore,
            onClick: () => toggleAmenitiesModal(true),
          }}
        >
          {i18n.rooms.showMore}
        </Button>
      </div>
      <FullscreenModal
        title={i18n.rooms.amenities(site.room_term)}
        isOpen={showAmenitiesModal}
        shareView={shareView}
        onClose={() => toggleAmenitiesModal(false)}
        content={
          <>
            <Headline
              size={4}
              title={room.description}
              as="h2"
              className={styles.description}
            />
            <div className={styles.modalAmenities}>
              {room.amenityGroups.map((group) => (
                <div key={group.id}>
                  <Headline
                    size={5}
                    title={group.name}
                    as="h3"
                    className={styles.modalGroupHeadline}
                  />
                  <ul className={styles.modalAmenityList}>
                    {group.amenities.map((amenity) => {
                      const AmenityIcon = AmenityIcons[amenity.id];
                      return (
                        <li key={amenity.id} className={styles.modalAmenity}>
                          {AmenityIcon && (
                            <AmenityIcon
                              aria-hidden
                              className={styles.modalAmenityIcon}
                            />
                          )}
                          {amenity.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </>
        }
      />
    </div>
  );
};

export default FavoredAmenities;
