import clsx from "clsx";
import { PropsWithChildren } from "react";
import styles from "./CenterWrapper.module.css";

type CenterWrapperProps = PropsWithChildren<{
  className?: string;
}>;

const CenterWrapper = ({ children, className }: CenterWrapperProps) => {
  return (
    <div className={clsx(styles.centerContent, className)}>{children}</div>
  );
};

export default CenterWrapper;
