import { Field, Input as HeadlessInput, Label } from "@headlessui/react";
import clsx from "clsx";
import { forwardRef, useId, useState } from "react";
import { FieldError } from "react-hook-form";
import FormError from "./FormError";
import styles from "./Input.module.css";

interface InputProps {
  label: string;
  required?: boolean;
  isEmpty?: boolean;
  className?: string;
  error?: FieldError | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  type?: "text" | "email" | "tel";
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      required,
      isEmpty,
      className,
      error,
      onBlur,
      type = "text",
      ...rest
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);
    const [autoFilled, setAutoFilled] = useState(false);
    const errorId = useId();

    return (
      <Field
        className={clsx(styles.inputWrapper, className, {
          [styles.isInvalid]: !!error,
        })}
      >
        <Label
          className={clsx(styles.label, {
            [styles.focusedOrFilled]: !isEmpty || focused || autoFilled,
          })}
        >
          {label + (required ? "*" : "")}
        </Label>
        <HeadlessInput
          type={type}
          {...rest}
          ref={ref}
          className={styles.input}
          aria-invalid={!!error}
          aria-required={required}
          aria-errormessage={error ? errorId : undefined}
          onFocus={() => setFocused(true)}
          onBlur={(...args) => {
            setFocused(false);
            onBlur?.(...args);
          }}
          /* required to trigger animation on autofill preview which then can be detected via javascript */
          onAnimationStart={(e) => {
            switch (e.animationName) {
              case styles["on-auto-fill-start"]:
                setAutoFilled(true);
                break;

              case styles["on-auto-fill-cancel"]:
                setAutoFilled(false);
                break;
            }
          }}
        />
        <FormError id={errorId} errorMessage={error?.message ?? ""} />
      </Field>
    );
  },
);

Input.displayName = "Input";

export default Input;
