import clsx from "clsx";
import { useEffect, useState } from "react";
import { OrderDirection } from "../../@types";
import { useBookingStore } from "../../hooks/useBookingStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import { translate } from "../../i18n";
import { ActionFooter } from "../../ui/ActionFooter";
import Button from "../../ui/Button";
import Headline from "../../ui/Headline";
import Ascending from "../../ui/icon/ascending.svg?react";
import Descending from "../../ui/icon/descending.svg?react";
import Sort from "../../ui/icon/sort.svg?react";
import MobileModal, {
  MobileModalContent,
  MobileModalHeader,
} from "../../ui/MobileModal";
import { site } from "../../utils/site";
import styles from "./RoomsSort.module.css";
import { OfferRoomOrderCriterion, OfferRoomSortKey } from "./utils";

interface RoomsSortProps {
  orderCriterion: OfferRoomOrderCriterion | undefined;
  onSort: (orderCriterion?: OfferRoomOrderCriterion) => void;
}

const RoomsSort = ({ orderCriterion, onSort }: RoomsSortProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showSortModal, setShowSortModal] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1126px)");
  const [selectedOrderCriterion, setSelectedOrderCriterion] =
    useState(orderCriterion);

  const onClose = () => {
    setSelectedOrderCriterion(orderCriterion);
    onSort(orderCriterion);
    setShowSortModal(false);
  };

  useEffect(() => {
    setSelectedOrderCriterion(orderCriterion);
  }, [orderCriterion]);

  return (
    <>
      <Button
        layout="link"
        buttonProps={{
          title: i18n.rooms.sort,
          className: clsx(styles.sortOpenerButton, {
            [styles.active]: !!orderCriterion,
          }),
          onClick: () => setShowSortModal(true),
        }}
      >
        <Sort className={styles.sortOpenerButtonIcon} />
        {i18n.rooms.sort}
      </Button>
      {showSortModal && (
        <MobileModal isOpen align="center" size="small" onClose={onClose}>
          <MobileModalHeader
            title={
              isDesktop
                ? i18n.rooms.sortHeadline[site.guest_interaction](
                    site.room_term,
                  )
                : i18n.rooms.sort
            }
            onClose={onClose}
            hideOnDesktop={false}
          />
          <MobileModalContent className={styles.content}>
            <div>
              <Headline
                size={5}
                title={i18n.rooms.price}
                className={styles.headline}
              />
              <div className={styles.buttons}>
                <SortButton
                  orderCriterion={{
                    direction: OrderDirection.Ascending,
                    sortKey: OfferRoomSortKey.OfferPrice,
                  }}
                  title={i18n.rooms.ascending}
                  text={i18n.rooms.ascendingText}
                  onSort={setSelectedOrderCriterion}
                  selectedOrderCriterion={selectedOrderCriterion}
                  icon={Ascending}
                />
                <SortButton
                  orderCriterion={{
                    direction: OrderDirection.Descending,
                    sortKey: OfferRoomSortKey.OfferPrice,
                  }}
                  title={i18n.rooms.descending}
                  text={i18n.rooms.descendingText}
                  onSort={setSelectedOrderCriterion}
                  selectedOrderCriterion={selectedOrderCriterion}
                  icon={Descending}
                />
              </div>
            </div>
            <div>
              <Headline
                size={5}
                title={i18n.rooms.size}
                className={styles.headline}
              />
              <div className={styles.buttons}>
                <SortButton
                  orderCriterion={{
                    direction: OrderDirection.Ascending,
                    sortKey: OfferRoomSortKey.RoomSize,
                  }}
                  title={i18n.rooms.ascending}
                  text={i18n.rooms.ascendingText}
                  onSort={setSelectedOrderCriterion}
                  selectedOrderCriterion={selectedOrderCriterion}
                  icon={Ascending}
                />
                <SortButton
                  orderCriterion={{
                    direction: OrderDirection.Descending,
                    sortKey: OfferRoomSortKey.RoomSize,
                  }}
                  title={i18n.rooms.descending}
                  text={i18n.rooms.descendingText}
                  onSort={setSelectedOrderCriterion}
                  selectedOrderCriterion={selectedOrderCriterion}
                  icon={Descending}
                />
              </div>
            </div>
          </MobileModalContent>
          <ActionFooter>
            <Button
              buttonProps={{
                title: i18n.rooms.resetSort,
                onClick: () => {
                  setSelectedOrderCriterion(orderCriterion);
                  onSort();
                  setShowSortModal(false);
                },
              }}
              layout="secondary"
            >
              {i18n.rooms.resetSort}
            </Button>
            <Button
              buttonProps={{
                title: i18n.rooms.applySort,
                onClick: () => {
                  onSort(selectedOrderCriterion);
                  setShowSortModal(false);
                },
              }}
              layout="primary"
            >
              {i18n.rooms.applySort}
            </Button>
          </ActionFooter>
        </MobileModal>
      )}
    </>
  );
};

interface SortButtonProps {
  orderCriterion: OfferRoomOrderCriterion;
  title: string;
  text: string;
  selectedOrderCriterion: OfferRoomOrderCriterion | undefined;
  icon: React.ElementType;
  onSort: (orderCriterion: OfferRoomOrderCriterion) => void;
}

const SortButton = ({
  orderCriterion,
  title,
  text,
  selectedOrderCriterion,
  icon,
  onSort,
}: SortButtonProps) => {
  const IconComponent = icon;

  return (
    <Button
      layout="link"
      buttonProps={{
        onClick: () => onSort(orderCriterion),
        title,
        className: clsx(styles.sortButton, {
          [styles.sortButtonActive]:
            orderCriterion.sortKey === selectedOrderCriterion?.sortKey &&
            orderCriterion.direction === selectedOrderCriterion.direction,
        }),
      }}
    >
      <span className={styles.buttonContent}>
        <Headline size={2} title={title} as="span" />
        <span className={styles.text}>{text}</span>
      </span>
      <IconComponent className={styles.sortButtonIcon} />
    </Button>
  );
};

export default RoomsSort;
