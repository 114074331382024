import { BookingStep, Prices as PricesType } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import {
  RatePlan as RatePlanType,
  RatePlanType as RatePlanTypes,
} from "../../http/ratePlanApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import Check from "../../ui/icon/check.svg?react";
import Special from "../../ui/icon/special.svg?react";
import Prices from "../../ui/Prices";
import styles from "./RatePlan.module.css";

interface RatePlanProps {
  ratePlan: RatePlanType | undefined;
  prices: PricesType;
  className?: string;
}

const RatePlan = ({ ratePlan, prices, className = "" }: RatePlanProps) => {
  const language = useBookingStore((state) => state.language);
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const setStep = useBookingStoreSetStep();
  const i18n = translate(language);

  if (!ratePlan) {
    return null;
  }

  const toggleDetailView = () => {
    updateRoomConfiguration({ ratePlanId: ratePlan.id });
    setStep(BookingStep.RatePlan);
  };

  return (
    <Card
      className={className}
      footer={
        <Button
          buttonProps={{
            title: i18n.ratePlans.continue,
            onClick: () => toggleDetailView(),
          }}
        >
          {i18n.ratePlans.continue}
        </Button>
      }
    >
      <div className={styles.titleContainer}>
        <Button
          layout="link"
          buttonProps={{
            title: ratePlan.title,
            onClick: () => toggleDetailView(),
            className: styles.rateTitle,
          }}
        >
          {ratePlan.title}
        </Button>
        {ratePlan.type === RatePlanTypes.Special && (
          <Special className={styles.specialIcon} />
        )}
      </div>
      <Prices
        minPrice={prices.min}
        minPricePerNight={prices.min_per_night}
        discount={prices.discount}
        basePrice={prices.base}
      />
      {ratePlan.inclusives.length > 0 && (
        <ul className={styles.inclusives}>
          {ratePlan.inclusives.map((inclusive) => (
            <li key={inclusive.id} className={styles.inclusiveItem}>
              <Check aria-hidden className={styles.inclusiveItemIcon} />
              {inclusive.title}
            </li>
          ))}
        </ul>
      )}
      <div className={styles.detailLinkWrapper}>
        <Button
          layout="link"
          buttonProps={{
            className: styles.detailLink,
            title: i18n.ratePlans.showMoreDetails,
            onClick: () => toggleDetailView(),
          }}
        >
          {i18n.ratePlans.showMoreDetails}
        </Button>
      </div>
    </Card>
  );
};

export default RatePlan;
