import { useBookingStore } from "../../hooks/useBookingStore";
import { useBooking } from "../../http/bookingApi";
import { translate } from "../../i18n";
import StaticPage from "../../pages/StaticPage";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import Insurance from "../../ui/icon/insurance.svg?react";
import Party from "../../ui/icon/party.svg?react";
import { site } from "../../utils/site";
import styles from "./CheckoutSuccess.module.css";

const CheckoutSuccess = () => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { data: booking } = useBooking();

  if (!booking) {
    return null;
  }

  return (
    <StaticPage>
      <div className={styles.content}>
        <Party className={styles.icon} aria-hidden />
        <Headline
          size={2}
          title={i18n.checkout.success.headline}
          className={styles.headline}
        />
        <Headline
          size={4}
          title={i18n.checkout.success.text[site.guest_interaction](
            booking.guest.email_address,
          )}
          className={styles.text}
        />
      </div>
      <CenterWrapper>
        <div className={styles.insuranceBlock}>
          <Insurance className={styles.insuranceIcon} aria-hidden />
          <Headline
            size={2}
            title={
              i18n.checkout.success.insuranceHeadline[site.guest_interaction]
            }
            className={styles.insuranceHeadline}
          />
          <Headline
            size={4}
            title={i18n.checkout.success.insuranceText[site.guest_interaction]}
            className={styles.insuranceText}
          />
          <Button
            buttonProps={{
              title: i18n.checkout.success.insuranceButtonText,
              onClick: () => alert("Insurance button clicked"),
              className: styles.insuranceButton,
            }}
          >
            {i18n.checkout.success.insuranceButtonText}
          </Button>
        </div>
      </CenterWrapper>
    </StaticPage>
  );
};

export default CheckoutSuccess;
