import clsx from "clsx";
import { PropsWithChildren } from "react";
import styles from "./ActionFooter.module.css";

type ActionFooterProps = PropsWithChildren<{
  className?: string;
}>;

export const ActionFooter = ({ className, children }: ActionFooterProps) => {
  return <div className={clsx(styles.actionFooter, className)}>{children}</div>;
};
