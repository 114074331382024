import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { TotalPrices } from "../../@types";
import { selectNights, useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Headline from "../../ui/Headline";
import Close from "../../ui/icon/close.svg?react";
import { formatMoney } from "../../utils/number";
import styles from "./CheckoutPricePopover.module.css";

interface CheckoutPricePopoverProps {
  prices: TotalPrices | undefined;
}

const CheckoutPricePopover = ({ prices }: CheckoutPricePopoverProps) => {
  const language = useBookingStore((state) => state.language);
  const nights = useBookingStore(selectNights);
  const i18n = translate(language);

  if (!prices) {
    return null;
  }

  return (
    <Popover className={styles.container}>
      <PopoverButton className={styles.button}>
        {i18n.checkout.showPriceDetails}
      </PopoverButton>
      <PopoverPanel className={styles.panel}>
        {({ close }) => (
          <div className={styles.panelContent}>
            <div className={styles.block}>
              <div className={styles.item}>
                <Headline
                  size={2}
                  title={i18n.checkout.forNights(nights ?? 0)}
                />
                <Button
                  layout="link"
                  buttonProps={{
                    onClick: () => close(),
                    "aria-label": i18n.general.close,
                    className: styles.closeButton,
                  }}
                >
                  <Close className={styles.closeIcon} />
                </Button>
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.item}>
                <span>{i18n.checkout.basePrice}</span>
                {formatMoney(prices.total_without_vat.amount, language)}
              </div>
              <div className={styles.item}>
                <span>{i18n.checkout.touristTax}</span>
                {formatMoney(prices.tourist_tax.amount, language)}
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.item}>
                <Headline size={3} title={i18n.checkout.totalPrice} />
                <Headline
                  size={3}
                  title={formatMoney(prices.total.amount, language)}
                />
              </div>
            </div>
          </div>
        )}
      </PopoverPanel>
    </Popover>
  );
};

export default CheckoutPricePopover;
