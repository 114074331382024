import useSWR from "swr";
import {
  Image,
  Language,
  Price,
  Prices,
  ReservationPolicies,
  TotalPrices,
} from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { PrepaymentType } from "./bookingApi";
import { BoardType } from "./ratePlanApi";
import { api, fetcher } from "./utils";

interface Offers {
  offers: Offer[];
}

export interface Offer {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  rates: Rates[];
}

export interface OfferBoard {
  board_type: BoardType;
  surcharge: Price;
}

interface Rates {
  rate_plan_id: string;
  prices: Prices;
  board_types: OfferBoard[];
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const offersURL = "/offers.json";

export const useOffers = () => {
  const { data, ...rest } = useSWR({ url: offersURL }, async (opts) => {
    const { offers } = await fetcher<Offers>(opts);

    return {
      offers,
      offersMap: Object.fromEntries(
        offers.map((x) => [`${x.room_index}_${x.room_type_id}`, x]),
      ),
    };
  });

  return {
    ...rest,
    ...data,
  };
};

interface AutoOffers {
  auto_offers: AutoOffer[];
}

export interface AutoOffer {
  total_prices: Prices;
  rooms: AutoOfferRoom[];
  roomsMap: Record<string, AutoOfferRoom>;
}

export interface AutoOfferRoom {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  occupancy: {
    adults: number;
    children?: number[];
  };
  rates: Rates[];
}

// TODO: replace with live url: `/properties/${propertyId}/auto_offers`
const autoOffersURL = "/auto-offers-de.json";

export const useAutoOffers = () => {
  const language = useBookingStore((state) => state.language);

  const { data, ...rest } = useSWR(
    { url: autoOffersURL, language },
    async (opts) => {
      const { auto_offers: autoOffers } = await fetcher<AutoOffers>(opts);

      autoOffers.forEach((ao) => {
        ao.roomsMap = Object.fromEntries(
          ao.rooms.map((x) => [`${x.room_index}_${x.room_type_id}`]),
        );
      });

      return {
        autoOffers,
      };
    },
  );

  return {
    ...rest,
    ...data,
  };
};

interface PortalPrices {
  portal_prices: PortalPrice[];
}

interface PortalPrice {
  name: string;
  room_index?: number;
  icon: Image;
  price: Price;
}

// TODO: replace with live url: `/properties/${propertyId}/portal_prices`
const portalPricesURL = "/portal-prices.json";

export const usePortalPrices = () => {
  const { data, ...rest } = useSWR(
    { url: portalPricesURL },
    async (opts) => (await fetcher<PortalPrices>(opts)).portal_prices,
  );

  return {
    ...rest,
    portalPrices: data,
  };
};

export interface OfferSelection {
  adults: number;
  children?: number[];
  room_type_id: string;
  rate_plan_id: string;
  board_type: BoardType | null;
}

export interface SelectedOfferBody {
  offer_selections: OfferSelection[];
  insurance: boolean;
  voucher_code?: string | undefined;
}

interface SelectedOfferRoom {
  room_type_id: string;
  name: string;
  occupancy: {
    adults: number;
    children?: number[];
  };
  rate_plan: {
    id: string;
    title: string;
    reservation_policies: string;
  };
  board_type: BoardType;
  price: Price;
}

interface ExtendStaySuggestion {
  arrival: string;
  departure: string;
  discount: number;
}

export interface SelectedOfferResponse {
  check_in: {
    from: Date;
    to: Date;
  };
  check_out: {
    from: Date;
    to: Date;
  };
  rooms: SelectedOfferRoom[];
  prices: TotalPrices;
  prepayment: {
    type: PrepaymentType;
    price: Price;
    percentage: number;
  };
  insurance?: {
    name: string;
    price: Price;
    description: string;
  };
  extend_stay_suggestions: ExtendStaySuggestion[];
  reservation_policies: ReservationPolicies;
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const postOffersURL = "/post-offers-de.json";
interface PostSelectedOffersOptions {
  arrival: Date | null;
  departure: Date | null;
  language: Language;
  body: SelectedOfferBody;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const postSelectedOffers = (_options: PostSelectedOffersOptions) => {
  /*const searchParams = new URLSearchParams();
  const arrivalString = formatDateNullable(options.arrival);
  if (arrivalString) {
    searchParams.set("arrival", arrivalString);
  }
  const departureString = formatDateNullable(options.departure);
  if (departureString) {
    searchParams.set("departure", departureString);
  }

  const url = `${postOffersURL}${searchParams.size ? `?${searchParams.toString()}` : ""}`;
  return api
    .url(url)
    .headers({ "Accept-Language": options.language })
    .post(options.body)
    .json<SelectedOfferResponse>();*/
  // TODO: replace with POST request
  return api.url(postOffersURL).get().json<SelectedOfferResponse>();
};
