import clsx from "clsx";
import { BookingStep } from "../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "../ui/Button";
import Mail from "../ui/icon/mail.svg?react";
import Phone from "../ui/icon/phone.svg?react";
import { site } from "../utils/site";
import { removeAllWhitespace } from "../utils/string";
import styles from "./HeaderLogos.module.css";

interface HeaderLogosProps {
  showMobileLogo?: boolean;
}

const HeaderLogos = ({ showMobileLogo = false }: HeaderLogosProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const setStep = useBookingStoreSetStep();

  return (
    <>
      <div className={styles.icons}>
        <a
          href={`tel:${removeAllWhitespace(site.phone_number)}`}
          className={styles.iconLink}
          aria-label={i18n.start.phone}
        >
          <Phone className={styles.icon} />
        </a>
        <a
          href={`mailto:${site.email}`}
          className={styles.iconLink}
          aria-label={i18n.start.email}
        >
          <Mail className={styles.icon} />
        </a>
      </div>
      <Button
        layout="link"
        buttonProps={{
          className: clsx(styles.logoButton, {
            [styles.mobileLogoButton]: showMobileLogo,
          }),
          title: site.property_name,
          onClick: () => {
            setStep(BookingStep.Start);
            history.replaceState(history.state, "", `/${language}`);
          },
        }}
      >
        <img
          className={clsx(styles.logo, {
            [styles.mobileLogo]: showMobileLogo,
          })}
          alt={site.property_name}
          title={site.property_name}
          src={site.logo.url}
          width={site.logo.width}
          height={site.logo.height}
        />
      </Button>
    </>
  );
};

export default HeaderLogos;
