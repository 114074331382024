import useSWR from "swr";
import { useBookingStore } from "../hooks/useBookingStore";
import { fetcher } from "./utils";

interface LegalTexts {
  imprint: string;
  privacy: string;
  terms: string;
}

// TODO: replace with live url: `/sites/${siteId}/legal_texts`
const url = "/legal-texts-de.json";

export const useLegalTexts = (shouldFetch: boolean) => {
  const language = useBookingStore((state) => state.language);

  return useSWR(shouldFetch ? { url, language } : null, (opts) =>
    fetcher<LegalTexts>(opts),
  );
};
