import useSWR from "swr";
import { useBookingStore } from "../hooks/useBookingStore";
import { fetcher } from "./utils";

interface Country {
  code: string;
  name: string;
}

interface Countries {
  countries: Country[];
}

// TODO: replace with live url: `/countries`
const countriesURL = "/countries-de.json";

export const useCountries = () => {
  const language = useBookingStore((state) => state.language);

  return useSWR({ url: countriesURL, language }, (opts) =>
    fetcher<Countries>(opts),
  );
};
