import { useRoomTypes } from "../../http/roomApi";
import StaticPage from "../../pages/StaticPage";
import RoomDetail from "./RoomDetail";

interface StaticRoomDetailProps {
  roomId: string;
}

const StaticRoomDetail = ({ roomId }: StaticRoomDetailProps) => {
  const { roomTypesMap } = useRoomTypes();
  const room = roomTypesMap?.[roomId];

  if (!room) {
    return null;
  }

  return (
    <StaticPage>
      <RoomDetail room={room} shareView />
    </StaticPage>
  );
};

export default StaticRoomDetail;
