import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import CalendarModal from "../domain/calendar/CalendarModal";
import CheckoutModal from "../domain/checkout/CheckoutModal";
import OccupancyModal from "../domain/occupancy/OccupancyModal";
import { useBookingStore } from "../hooks/useBookingStore";
import Header from "../layouts/Header";
import ResponsiveImage from "../ui/ResponsiveImage";
import { getImagesByOrientation } from "../utils/image";
import { site } from "../utils/site";
import styles from "./PageWithHeader.module.css";

type PageWithHeaderProps = PropsWithChildren<{
  backgroundImage?: boolean;
  header?: ReactNode;
  contentClassName?: string;
}>;

const PageWithHeader = ({
  backgroundImage,
  header,
  children,
  contentClassName,
}: PageWithHeaderProps) => {
  const bgImage = getImagesByOrientation(site.background_image.derivatives);
  const language = useBookingStore((state) => state.language);
  const bgDescription = site.background_image.description[language];

  return (
    <>
      <Header />
      {backgroundImage && (
        <ResponsiveImage
          className={styles.backgroundImage}
          alt={bgDescription}
          title={bgDescription}
          srcSet={bgImage.landscape}
          srcSetPortrait={bgImage.portrait}
          sizes="100vw"
        />
      )}
      <div className={clsx(styles.content, contentClassName)}>
        {header && <div className={styles.contentHeader}>{header}</div>}
        <OccupancyModal />
        <CalendarModal />
        <CheckoutModal />
        {children}
      </div>
    </>
  );
};

export default PageWithHeader;
