import { Field, Textarea as HeadlessTextarea, Label } from "@headlessui/react";
import clsx from "clsx";
import { forwardRef, useId, useState } from "react";
import { FieldError } from "react-hook-form";
import FormError from "./FormError";
import styles from "./Textarea.module.css";

interface TextareaProps {
  label: string;
  required?: boolean;
  isEmpty?: boolean;
  className?: string;
  error?: FieldError | undefined;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, required, isEmpty, className, error, onBlur, ...rest }, ref) => {
    const [focused, setFocused] = useState(false);
    const errorId = useId();

    return (
      <Field
        className={clsx(styles.textareaWrapper, className, {
          [styles.isInvalid]: !!error,
        })}
      >
        <Label
          className={clsx(styles.label, {
            [styles.focusedOrFilled]: !isEmpty || focused,
          })}
        >
          {label + (required ? "*" : "")}
        </Label>
        <HeadlessTextarea
          {...rest}
          ref={ref}
          className={styles.textarea}
          aria-invalid={!!error}
          aria-required={required}
          aria-errormessage={error ? errorId : undefined}
          onFocus={() => setFocused(true)}
          onBlur={(...args) => {
            setFocused(false);
            onBlur?.(...args);
          }}
        />
        <FormError id={errorId} errorMessage={error?.message ?? ""} />
      </Field>
    );
  },
);

Textarea.displayName = "Textarea";

export default Textarea;
