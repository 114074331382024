import clsx from "clsx";
import { differenceInDays } from "date-fns";
import { useShallow } from "zustand/react/shallow";
import { BookingStep, HeaderButtonCategory } from "../../@types";
import {
  getOccupancySummary,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Date from "../../ui/Date";
import Headline from "../../ui/Headline";
import Edit from "../../ui/icon/edit.svg?react";
import Night from "../../ui/icon/night.svg?react";
import Plus from "../../ui/icon/plus.svg?react";
import { formatIntlDate, parseDateNullable } from "../../utils/date";
import { formatMoney } from "../../utils/number";
import { site } from "../../utils/site";
import CheckoutPricePopover from "./CheckoutPricePopover";
import styles from "./CheckoutSummary.module.css";
import { CheckoutStep } from "./utils";

interface CheckoutSummaryProps {
  onChangeStep?: (step: number) => void;
  modal?: boolean;
}

const CheckoutSummary = ({ onChangeStep, modal }: CheckoutSummaryProps) => {
  const checkoutData = useBookingStore((state) => state.checkoutData);
  const language = useBookingStore((state) => state.language);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const extendStay = useBookingStore((state) => state.extendStay);
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );
  const setStep = useBookingStoreSetStep();
  const i18n = translate(language);

  const occupancySummary = getOccupancySummary(
    useBookingStore(
      useShallow((state) => ({
        autoOccupancy: state.autoOccupancy,
        occupancies: state.occupancies,
      })),
    ),
  );

  const extendArrivalDeparture = (
    arrival: Date | null,
    departure: Date | null,
  ) => {
    extendStay({
      arrival,
      departure,
    });
    updateCheckoutData();
  };

  return (
    <>
      {!modal && (
        <Headline
          as="h1"
          size={2}
          title={i18n.checkout.summaryHeadline[site.guest_interaction]}
          className={styles.headline}
        />
      )}
      <div className={styles.block}>
        <Headline
          as="h3"
          size={3}
          title={i18n.checkout.travelPeriod}
          className={styles.blockHeadline}
        />
        <div className={styles.datesWrapper}>
          <div className={styles.dateBlock}>
            {i18n.checkout.arrival}
            {arrival ? (
              <>
                <Date
                  className={styles.date}
                  date={arrival}
                  formatter={formatIntlDate(language, { year: "2-digit" })}
                />
                {site.check_in.from && site.check_in.from}
                {site.check_in.to && ` - ${site.check_in.to}`}
                {` ${i18n.checkout.time}`}
              </>
            ) : (
              <div>{i18n.checkout.dateNotSelected}</div>
            )}
          </div>
          <div className={styles.dateBlock}>
            {i18n.checkout.departure}
            {departure ? (
              <>
                <Date
                  className={styles.date}
                  date={departure}
                  formatter={formatIntlDate(language, { year: "2-digit" })}
                />
                {site.check_out.from && site.check_out.from}
                {site.check_out.to && ` - ${site.check_out.to}`}
                {` ${i18n.checkout.time}`}
              </>
            ) : (
              <div>{i18n.checkout.dateNotSelected}</div>
            )}
          </div>
        </div>
        <Button
          layout="link"
          buttonProps={{
            onClick: () => setActiveHeaderButton(HeaderButtonCategory.Calendar),
            "aria-label": i18n.general.edit,
            className: styles.editButton,
          }}
        >
          <Edit />
        </Button>
      </div>
      <div className={styles.block}>
        <Headline
          as="h3"
          size={3}
          title={i18n.checkout.guests}
          className={styles.blockHeadline}
        />
        <div className={styles.guests}>
          <div>{i18n.checkout.adultsCountSummary(occupancySummary.adults)}</div>
          {occupancySummary.children > 0 && (
            <div>
              {i18n.checkout.childrenCountSummary(occupancySummary.children)}
            </div>
          )}
        </div>
        <Button
          layout="link"
          buttonProps={{
            onClick: () =>
              setActiveHeaderButton(HeaderButtonCategory.Occupancy),
            "aria-label": i18n.general.edit,
            className: styles.editButton,
          }}
        >
          <Edit />
        </Button>
      </div>
      {checkoutData?.rooms.map((room, index) => (
        <div className={styles.block} key={index}>
          <Headline
            as="h3"
            size={3}
            title={i18n.checkout.roomsCountSummary(index + 1, site.room_term)}
            className={styles.blockHeadline}
          />
          <div className={styles.priceWrapper}>
            <Headline as="h4" size={5} title={room.name} />
            <Headline
              size={5}
              title={formatMoney(room.price.amount, language)}
              className={styles.roomPrice}
            />
          </div>
          <div>
            {`${i18n.checkout.guests}: ${i18n.checkout.adultsCountSummary(room.occupancy.adults)}`}
            {room.occupancy.children &&
              room.occupancy.children.length > 0 &&
              ` & ${i18n.checkout.childrenCountSummary(room.occupancy.children.length)}`}
          </div>
          <div>{`${i18n.checkout.ratePlan}: ${room.rate_plan.title}`}</div>
          <div>{`${i18n.checkout.boardType}: ${i18n.boards[room.board_type]}`}</div>
          <Button
            layout="link"
            buttonProps={{
              onClick: () => setStep(BookingStep.Rooms),
              "aria-label": i18n.general.edit,
              className: styles.editButton,
            }}
          >
            <Edit />
          </Button>
        </div>
      ))}
      <div className={styles.block}>
        <Headline
          as="h3"
          size={3}
          title={i18n.checkout.extras}
          className={styles.blockHeadline}
        />
        <Button
          layout="link"
          buttonProps={{
            onClick: () => setStep(BookingStep.Extras),
            "aria-label": i18n.general.edit,
            className: styles.editButton,
          }}
        >
          <Edit />
        </Button>
      </div>
      {!modal && (
        <>
          {arrival &&
            departure &&
            checkoutData?.extend_stay_suggestions.map((suggestion, index) => {
              const suggestedArrival = parseDateNullable(suggestion.arrival);
              const suggestedDeparture = parseDateNullable(
                suggestion.departure,
              );
              const extendArrivalNights =
                suggestedArrival !== null
                  ? differenceInDays(arrival, suggestedArrival)
                  : 0;
              const extendDepartureNights =
                suggestedDeparture !== null
                  ? differenceInDays(suggestedDeparture, departure)
                  : 0;

              if (extendArrivalNights + extendDepartureNights <= 0) {
                return null;
              }

              return (
                <div
                  key={index}
                  className={clsx(styles.block, styles.extendStayBlock)}
                >
                  <Night className={styles.extendStayIcon} aria-hidden />
                  <Headline
                    as="h3"
                    size={3}
                    title={i18n.checkout.extendStayHeadline}
                    className={styles.extendStayHeadline}
                  />
                  <Headline
                    size={4}
                    title={i18n.checkout.extendStayText[site.guest_interaction](
                      suggestion.discount,
                    )}
                    className={styles.extendStayText}
                  />
                  {extendArrivalNights > 0 && (
                    <Button
                      glyph={Plus}
                      buttonProps={{
                        title:
                          i18n.checkout.extendStayBefore(extendArrivalNights),
                        onClick: () =>
                          extendArrivalDeparture(suggestedArrival, null),
                        className: styles.extendStayButton,
                      }}
                    >
                      {i18n.checkout.extendStayBefore(2)}
                    </Button>
                  )}
                  {extendDepartureNights > 0 && (
                    <Button
                      glyph={Plus}
                      buttonProps={{
                        title: i18n.checkout.extendStayAfter(
                          extendDepartureNights,
                        ),
                        onClick: () =>
                          extendArrivalDeparture(null, suggestedDeparture),
                        className: styles.extendStayButton,
                      }}
                    >
                      {i18n.checkout.extendStayAfter(2)}
                    </Button>
                  )}
                </div>
              );
            })}
          <div className={clsx(styles.block, styles.priceBlock)}>
            <div className={styles.price}>
              <Headline size={1} title={i18n.checkout.total} />
              <Headline
                size={1}
                title={formatMoney(
                  checkoutData?.prices.total.amount ?? 0,
                  language,
                )}
              />
            </div>
            <div className={styles.priceInfo}>{i18n.checkout.priceInfo}</div>
            <CheckoutPricePopover prices={checkoutData?.prices} />
            {onChangeStep && (
              <Button
                layout="primary"
                buttonProps={{
                  title: i18n.checkout.continueToForm[site.guest_interaction],
                  onClick: () => onChangeStep(CheckoutStep.Form),
                  className: styles.continueButton,
                }}
              >
                {i18n.checkout.continueToForm[site.guest_interaction]}
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CheckoutSummary;
