import useSWR from "swr";
import { Photo, Price, TotalPrices } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { OfferSelection } from "./offerApi";
import { BoardType } from "./ratePlanApi";
import { api, fetcher } from "./utils";

export enum PrepaymentType {
  Undefined = "",
  BankTransfer = "bank_transfer",
  Card = "card",
  CardAsSecurity = "card_as_security",
  NoSecurity = "no_security",
}

export enum Gender {
  None = "",
  Male = "male",
  Female = "female",
}

enum CancellationReason {
  GuestUnableToStay = "guest_unable_to_stay",
  PropertyAskedToCancel = "property_asked_to_cancel",
  GuestChoseOtherDestination = "guest_chose_other_destination",
  GuestChoseOtherProperty = "guest_chose_other_property",
  Other = "other",
}

interface BookingResponse {
  payment_url: string;
}

interface Company {
  name: string;
  vat_number: string;
  recipient_code?: string;
  street?: string;
  postal_code?: string;
  municipality?: string;
  country?: string;
}

export interface Guest {
  gender: Gender;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number?: string;
  note?: string;
  street?: string;
  postal_code?: string;
  municipality?: string;
  country?: string;
  company: Company;
}

interface BookingBody {
  offer_selections: OfferSelection[];
  insurance: boolean;
  voucher_code?: string;
  prepayment_type: PrepaymentType;
  guest: Guest;
}

// TODO: replace with live url: `/properties/${propertyId}/bookings`
const postBookingURL = "/bookings.json";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const postBooking = (_body: BookingBody) =>
  // TODO: replace with `api.url(postBookingURL).post(body).json<BookingResponse>();`
  api.url(postBookingURL).get().json<BookingResponse>();

interface BookingRoom {
  room_type_id: string;
  room_type_name: string;
  occupancy: {
    adults: number;
    children?: number[];
  };
  board_type: BoardType;
  photo: Photo;
}

interface Booking {
  booking_code: string;
  guest: Guest;
  arrival: string;
  departure: string;
  nights_count: number;
  rooms: BookingRoom[];
  total_prices?: TotalPrices;
  prepayment_type: PrepaymentType;
  cancellation_policies: string;
  days_to_arrival?: number;
  cancellation_fee?: Price;
}

// TODO: replace with live url: `/properties/${propertyId}/bookings/${bookingId}`
const bookingURL = "/get-booking-de.json";

export const useBooking = () => {
  const language = useBookingStore((state) => state.language);

  return useSWR({ url: bookingURL, language }, (opts) =>
    fetcher<Booking>(opts),
  );
};

interface CancelBookingBody {
  cancellation_reason: CancellationReason;
  other_reason_text?: string;
}

// TODO: replace with live url: `/properties/${propertyId}/bookings/${bookingId}`
const cancelBookingURL = "/get-booking-de.json";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const cancelBooking = (_body: CancelBookingBody) =>
  // TODO: replace with `api.url(cancelBookingURL).post(body).json();`
  api.url(cancelBookingURL).get().json();
