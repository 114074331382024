import wretch from "wretch";
import { FetcherOptions } from "../@types";

export const api = wretch().options({}).url("/example-data");

export const fetcher = <Response>({ url, language }: FetcherOptions) => {
  const headers: HeadersInit = {};
  if (language) {
    headers["Accept-Language"] = language;
  }
  return api.url(url).headers(headers).get().json<Response>();
};
