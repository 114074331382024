import { useEffect } from "react";
import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
  validate,
} from "../../hooks/useBookingStore";
import {
  bookingSuccessPattern,
  staticRoomDetailPattern,
} from "../../utils/constants";
import { site } from "../../utils/site";
import CheckoutSuccess from "../checkout/CheckoutSuccess";
import StaticRoomDetail from "../room/StaticRoomDetail";

const Init = () => {
  const bookingStoreState = useBookingStore((state) => state);
  const { setHydratedValuesValidated, hydratedValuesValidated } =
    bookingStoreState;
  const setStep = useBookingStoreSetStep();

  const matchStaticRoomDetailPattern = location.pathname.match(
    staticRoomDetailPattern,
  );

  const matchBookingSuccessPattern = location.pathname.match(
    bookingSuccessPattern,
  );

  useEffect(() => {
    if (
      hydratedValuesValidated ||
      matchStaticRoomDetailPattern ||
      matchBookingSuccessPattern
    ) {
      return;
    }

    const validationResult = validate(bookingStoreState, site);
    const firstInvalidStepResult = validationResult.steps.find((s) => !s.valid);
    setHydratedValuesValidated(true);
    setStep(firstInvalidStepResult?.step ?? BookingStep.Start, true);
  });

  if (matchStaticRoomDetailPattern?.[1]) {
    return <StaticRoomDetail roomId={matchStaticRoomDetailPattern[1]} />;
  }

  if (matchBookingSuccessPattern) {
    return <CheckoutSuccess />;
  }

  return null;
};

export default Init;
