import useSWR from "swr";
import { Image, Photo, ReservationPolicies } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { fetcher } from "./utils";

export enum RatePlanType {
  Standard = "standard",
  Special = "special",
}

export enum BoardType {
  OvernightStayOnly = "overnight_stay_only",
  BedAndBreakfast = "bed_and_breakfast",
  HalfBoard = "half_board",
  ThreeQuarterBoard = "three_quarter_board",
  FullBoard = "full_board",
  AllInclusive = "all_inclusive",
}

interface RatePlans {
  rate_plans: RatePlan[];
}

export interface Inclusive {
  id: string;
  title: string;
  description: string;
  photos: Photo[];
  thumbnail?: Image;
}

export interface RatePlanBoard {
  type: BoardType;
  description: string;
  photo?: Photo;
}

export interface RatePlan {
  id: string;
  type: RatePlanType;
  title: string;
  description: string;
  photos: Photo[];
  inclusives: Inclusive[];
  reservation_policies: ReservationPolicies;
  boards: RatePlanBoard[];
}

// TODO: replace with live url: `/properties/${propertyId}/rate_plans`
const ratePlansURL = "/rate-plans-de.json";

export const useRatePlans = () => {
  const language = useBookingStore((state) => state.language);

  const { data, ...rest } = useSWR(
    { url: ratePlansURL, language },
    async (opts) => {
      const { rate_plans: ratePlans } = await fetcher<RatePlans>(opts);

      return {
        ratePlans,
        ratePlansMap: Object.fromEntries(ratePlans.map((x) => [x.id, x])),
      };
    },
  );

  return {
    ...rest,
    ...data,
  };
};
