import clsx from "clsx";
import { useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import {
  isModalActive,
  selectModalIdentifierValue,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { Room, useRoomTypes } from "../../http/roomApi";
import { translate } from "../../i18n";
import { ActionFooter } from "../../ui/ActionFooter";
import Button from "../../ui/Button";
import CheckboxButton from "../../ui/CheckboxButton";
import Headline from "../../ui/Headline";
import ArrowDown from "../../ui/icon/arrow-down.svg?react";
import Children from "../../ui/icon/children.svg?react";
import Guests from "../../ui/icon/guests.svg?react";
import Plus from "../../ui/icon/plus.svg?react";
import Trash from "../../ui/icon/trash.svg?react";
import { MobileModalContent } from "../../ui/MobileModal";
import NumberInput from "../../ui/NumberInput";
import { maxRooms } from "../../utils/constants";
import { site } from "../../utils/site";
import RoomDetailModal from "../room/RoomDetailModal";
import styles from "./IndividualAssignmentForm.module.css";
import { useIndividualAssignmentFormContext } from "./utils";

const setValueOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

const IndividualAssignmentForm = () => {
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const language = useBookingStore((state) => state.language);
  const adults = autoOccupancy?.adults ?? 0;
  const children = autoOccupancy?.children ?? [];
  const [openedRoomIndex, setOpenedRoomIndex] = useState(0);
  const i18n = translate(language);
  const { control, formState, watch } = useIndividualAssignmentFormContext();

  const {
    fields: roomConfigurationFields,
    append,
    remove,
  } = useFieldArray({
    name: "roomConfigurations",
    control,
  });

  const roomConfigurations = watch("roomConfigurations");

  const unassignedOccupancy = roomConfigurations.reduce(
    (data, roomConfiguration) => {
      data.adults -= roomConfiguration.adults;
      data.children -= roomConfiguration.children;
      return data;
    },
    {
      adults,
      children: children.length,
    },
  );

  return (
    <>
      <div className={styles.remainingGuests}>
        <div className={styles.remainingGuestGroup}>
          <Guests
            className={styles.remainingGuestGroupIcon}
            title={i18n.autoOccupancy.adults}
            role="img"
          />
          <div className={styles.number}>
            <div className={styles.countWrapper}>
              <Headline
                className={styles.count}
                size={1}
                as="div"
                title={Math.floor(unassignedOccupancy.adults / 10)}
              />
            </div>
            <div className={styles.countWrapper}>
              <Headline
                className={styles.count}
                size={1}
                as="div"
                title={unassignedOccupancy.adults % 10}
              />
            </div>
          </div>
        </div>
        <div className={styles.remainingGuestGroup}>
          <Children
            className={styles.remainingGuestGroupIcon}
            title={i18n.autoOccupancy.children}
            role="img"
          />
          <div className={styles.number}>
            <div className={styles.countWrapper}>
              <Headline
                className={styles.count}
                size={1}
                as="div"
                title={Math.floor(unassignedOccupancy.children / 10)}
              />
            </div>
            <div className={styles.countWrapper}>
              <Headline
                className={styles.count}
                size={1}
                as="div"
                title={unassignedOccupancy.children % 10}
              />
            </div>
          </div>
        </div>
      </div>
      <MobileModalContent>
        {roomConfigurationFields.map((roomConfiguration, index) => (
          <RoomComponent
            key={roomConfiguration.id}
            index={index}
            open={index === openedRoomIndex}
            onOpen={setOpenedRoomIndex}
            onDelete={() => {
              remove(index);
              setOpenedRoomIndex(roomConfigurationFields.length - 1);
            }}
          />
        ))}
      </MobileModalContent>
      <ActionFooter>
        {roomConfigurationFields.length < maxRooms && (
          <Button
            buttonProps={{
              title: i18n.autoOccupancy.roomIndex(
                site.room_term,
                roomConfigurationFields.length + 1,
              ),
              disabled: !formState.isValid || unassignedOccupancy.adults === 0,
              onClick: () => {
                append({
                  roomTypeId: null,
                  adults: 0,
                  children: 0,
                  childAgeIndices: [],
                });
                setOpenedRoomIndex(roomConfigurationFields.length);
              },
            }}
            layout="secondary"
            glyph={Plus}
          >
            {i18n.autoOccupancy.roomIndex(
              site.room_term,
              roomConfigurationFields.length + 1,
            )}
          </Button>
        )}

        <Button
          buttonProps={{
            type: "submit",
            title: i18n.autoOccupancy.apply,
            disabled:
              !formState.isValid ||
              unassignedOccupancy.adults !== 0 ||
              unassignedOccupancy.children !== 0,
          }}
          layout="primary"
        >
          {i18n.autoOccupancy.apply}
        </Button>
      </ActionFooter>
    </>
  );
};

interface RoomProps {
  index: number;
  open: boolean;
  onOpen: (index: number) => void;
  onDelete: () => void;
}

const RoomComponent = ({ index, open, onOpen, onDelete }: RoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { roomTypes, roomTypesMap } = useRoomTypes();
  const { register, watch } = useIndividualAssignmentFormContext();

  const modalIdentifier = `IndividualAssignmentForm_room_detail`;
  const toggleRoomModal = useModalHistoryToggle(modalIdentifier);
  const showRoomModal = useModalHistoryStore(isModalActive(modalIdentifier));
  const modalRoomTypeId: string =
    useModalHistoryStore(selectModalIdentifierValue(modalIdentifier)) ?? "";

  const roomDetail = roomTypesMap?.[modalRoomTypeId];

  const [roomTypeId, adults, children] = watch([
    `roomConfigurations.${index}.roomTypeId`,
    `roomConfigurations.${index}.adults`,
    `roomConfigurations.${index}.children`,
  ]);

  const roomType = roomTypesMap?.[roomTypeId ?? ""];
  const guestCount = adults + children;
  const roomSummaryParts: string[] = [];
  if (guestCount > 0) {
    roomSummaryParts.push(i18n.autoOccupancy.guestsCountSummary(guestCount));
  }
  if (roomType) {
    roomSummaryParts.push(roomType.name);
  }

  const visualIndex = index + 1;

  const toggleRoomDetail = (room: Room | null) => {
    if (room?.id === modalRoomTypeId) {
      return;
    }
    toggleRoomModal(!!room, room?.id);
  };

  return (
    <div className={styles.room}>
      <div className={styles.header}>
        <Button
          layout="link"
          buttonProps={{
            onClick: () => onOpen(index),
            title: i18n.autoOccupancy.roomIndex(site.room_term, visualIndex),
            className: clsx(styles.opener, {
              [styles.activeOpener]: open,
            }),
            disabled: open,
          }}
        >
          {i18n.autoOccupancy.roomIndex(site.room_term, visualIndex)}
          <span className={styles.summary}>
            {!open &&
              roomSummaryParts.length > 0 &&
              `: ${roomSummaryParts.join(", ")}`}
          </span>
        </Button>
        {!open && (
          <div className={styles.actions}>
            <Button
              layout="link"
              buttonProps={{
                onClick: () => onDelete(),
                "aria-label": i18n.general.delete,
                className: styles.deleteButton,
              }}
            >
              <Trash className={styles.deleteIcon} />
            </Button>
            <Button
              layout="icon"
              buttonProps={{
                onClick: () => onOpen(index),
                "aria-label": i18n.general.expand,
                className: styles.expandButton,
              }}
              glyph={ArrowDown}
            />
          </div>
        )}
      </div>
      {open && (
        <div className={styles.roomTypes}>
          <RoomDetailModal
            room={roomDetail}
            open={showRoomModal}
            onClose={() => toggleRoomDetail(null)}
          />
          <input
            hidden
            disabled
            {...register(`roomConfigurations.${index}.roomTypeId`, {
              required: true,
            })}
          />
          <input
            hidden
            disabled
            {...register(`roomConfigurations.${index}.adults`, {
              min: 1,
              required: true,
            })}
          />
          <input
            hidden
            disabled
            {...register(`roomConfigurations.${index}.children`)}
          />
          {roomTypes?.map((roomType) => (
            <RoomTypeOption
              key={roomType.id}
              configurationIndex={index}
              roomType={roomType}
              setRoomDetail={toggleRoomDetail}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface RoomTypeOptionProps {
  configurationIndex: number;
  roomType: Room;
  setRoomDetail: (roomType: Room | null) => void;
}

const RoomTypeOption = ({
  configurationIndex,
  roomType,
  setRoomDetail,
}: RoomTypeOptionProps) => {
  const language = useBookingStore((state) => state.language);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const totalAdults = autoOccupancy?.adults ?? 0;
  const totalChildren = autoOccupancy?.children ?? [];
  const i18n = translate(language);
  const { control, setValue, watch } = useIndividualAssignmentFormContext();

  const [roomConfigurations, roomTypeId, adults, children, childAgeIndices] =
    watch([
      `roomConfigurations`,
      `roomConfigurations.${configurationIndex}.roomTypeId`,
      `roomConfigurations.${configurationIndex}.adults`,
      `roomConfigurations.${configurationIndex}.children`,
      `roomConfigurations.${configurationIndex}.childAgeIndices`,
    ]);

  const isDisabled = !!roomTypeId && roomType.id !== roomTypeId;

  const alreadyAssignedOccupancy = roomConfigurations.reduce(
    (data, roomConfiguration, index) => {
      if (configurationIndex !== index) {
        data.adults += roomConfiguration.adults;
        data.children += roomConfiguration.children;
      }

      return data;
    },
    {
      adults: 0,
      children: 0,
    },
  );

  const maxAdults = Math.min(
    totalAdults - alreadyAssignedOccupancy.adults,
    roomType.occupancy.max - children,
  );

  const maxChildren = Math.min(
    totalChildren.length - alreadyAssignedOccupancy.children,
    roomType.occupancy.max - adults,
  );

  return (
    <div>
      <Button
        layout="link"
        buttonProps={{
          className: styles.roomTypeName,
          title: roomType.name,
          disabled: isDisabled,
          onClick: () => setRoomDetail(roomType),
        }}
      >
        {roomType.name}
      </Button>
      <div
        className={clsx(styles.inputs, {
          [styles.disabledInputs]: isDisabled,
        })}
      >
        <Controller
          name={`roomConfigurations.${configurationIndex}.options.${roomType.id}.adults`}
          control={control}
          rules={{
            min: 0,
            max: maxAdults,
          }}
          disabled={isDisabled}
          render={() => {
            return (
              <NumberInput
                title={i18n.autoOccupancy.adults}
                value={isDisabled ? 0 : adults}
                min={0}
                max={maxAdults}
                disabled={isDisabled}
                description={i18n.autoOccupancy.adultsInfo}
                onChange={(value) => {
                  const roomTypeId = value + children ? roomType.id : null;
                  setValue(
                    `roomConfigurations.${configurationIndex}.roomTypeId`,
                    roomTypeId,
                    setValueOptions,
                  );
                  setValue(
                    `roomConfigurations.${configurationIndex}.adults`,
                    value,
                    setValueOptions,
                  );
                }}
              />
            );
          }}
        />
        {totalChildren.length > 0 && (
          <Controller
            name={`roomConfigurations.${configurationIndex}.options.${roomType.id}.children`}
            control={control}
            rules={{
              min: 0,
              max: maxChildren,
            }}
            render={() => (
              <NumberInput
                title={i18n.autoOccupancy.children}
                value={isDisabled ? 0 : children}
                min={0}
                max={maxChildren}
                disabled={isDisabled}
                description={i18n.autoOccupancy.childrenInfo(
                  site.children_min_age,
                )}
                onChange={(value) => {
                  const roomTypeId = value + adults > 0 ? roomType.id : null;
                  setValue(
                    `roomConfigurations.${configurationIndex}.roomTypeId`,
                    roomTypeId,
                    setValueOptions,
                  );
                  setValue(
                    `roomConfigurations.${configurationIndex}.children`,
                    value,
                    setValueOptions,
                  );
                  if (value < childAgeIndices.length) {
                    setValue(
                      `roomConfigurations.${configurationIndex}.childAgeIndices`,
                      childAgeIndices.slice(0, value),
                      setValueOptions,
                    );
                  }
                }}
              >
                {!isDisabled && (
                  <ChildAgeCheckboxGroup
                    configurationIndex={configurationIndex}
                  />
                )}
              </NumberInput>
            )}
          />
        )}
      </div>
    </div>
  );
};

interface ChildAgeCheckboxGroupProps {
  configurationIndex: number;
}

interface ChildAgeOption {
  index: number;
  value: number;
  disabled: boolean;
  checked: boolean;
}

const ChildAgeCheckboxGroup = ({
  configurationIndex,
}: ChildAgeCheckboxGroupProps) => {
  const language = useBookingStore((state) => state.language);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const i18n = translate(language);
  const totalChildren = autoOccupancy?.children ?? [];
  const { control, setValue, watch } = useIndividualAssignmentFormContext();

  const [roomConfigurations, childAgeIndices, childrenCount] = watch([
    `roomConfigurations`,
    `roomConfigurations.${configurationIndex}.childAgeIndices`,
    `roomConfigurations.${configurationIndex}.children`,
  ]);

  if (childrenCount === 0) {
    return null;
  }

  const assignedChildAgesToOther = roomConfigurations.reduce(
    (childAges: number[], roomConfiguration, index) => {
      if (configurationIndex === index) {
        return childAges;
      }

      childAges.push(...roomConfiguration.childAgeIndices);
      return childAges;
    },
    [],
  );

  const assignedChildAges = childAgeIndices.slice();
  const options: ChildAgeOption[] = totalChildren.map((value, index) => {
    const assignedChildAgesToOtherIndex =
      assignedChildAgesToOther.indexOf(index);
    const alreadyAssigned = assignedChildAgesToOtherIndex !== -1;
    if (alreadyAssigned) {
      assignedChildAgesToOther.splice(assignedChildAgesToOtherIndex, 1);
    }

    const assignedChildAgesIndex = assignedChildAges.indexOf(index);
    const checked = assignedChildAgesIndex !== -1;
    if (checked) {
      assignedChildAges.splice(assignedChildAgesIndex, 1);
    }

    return {
      index,
      value,
      checked,
      disabled:
        alreadyAssigned ||
        (childAgeIndices.length >= childrenCount && !checked),
    };
  });

  const handleChange = (option: ChildAgeOption) => {
    const newArray = [...childAgeIndices];

    const index = newArray.findIndex((x) => x === option.index);
    if (index !== -1 && option.checked) {
      newArray.splice(index, 1);
    } else {
      newArray.push(option.index);
    }

    setValue(
      `roomConfigurations.${configurationIndex}.childAgeIndices`,
      newArray,
      setValueOptions,
    );
  };

  return (
    <div className={styles.childrenContainer}>
      <Controller
        name={`roomConfigurations.${configurationIndex}.childAgeIndices`}
        control={control}
        rules={{
          validate: (value) => value.length === childrenCount,
        }}
        render={() => (
          <>
            {options.map((option, index) => (
              <CheckboxButton
                key={index}
                label={i18n.autoOccupancy.childrenAge(option.value)}
                name={`roomConfigurations.${configurationIndex}.childAgeIndices`}
                checked={option.checked}
                onChange={() => handleChange(option)}
                disabled={option.disabled}
              />
            ))}
          </>
        )}
      />
    </div>
  );
};

export default IndividualAssignmentForm;
