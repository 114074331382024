import { Field, Radio as HeadlessRadio, Label } from "@headlessui/react";
import { forwardRef } from "react";
import styles from "./Radio.module.css";

interface RadioProps {
  label: string;
  value: string | number;
  className?: string;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ label, value, className, ...rest }, ref) => {
    return (
      <Field className={className}>
        <Label className={styles.label}>
          <HeadlessRadio
            {...rest}
            ref={ref}
            className={styles.radio}
            value={value}
          />
          {label}
        </Label>
      </Field>
    );
  },
);

Radio.displayName = "Radio";

export default Radio;
