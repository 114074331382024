import styles from "./FormError.module.css";

interface FormErrorProps {
  errorMessage: string;
  id?: string;
}

const FormError = ({ errorMessage, id }: FormErrorProps) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <div role="alert" id={id} className={styles.error}>
      {errorMessage}
    </div>
  );
};

export default FormError;
