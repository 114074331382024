import { HeaderButtonCategory } from "../../@types";
import {
  selectBookingAmount,
  useBookingStore,
} from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Headline from "../../ui/Headline";
import MobileModal, { MobileModalHeader } from "../../ui/MobileModal";
import { formatMoney } from "../../utils/number";
import { site } from "../../utils/site";
import styles from "./CheckoutModal.module.css";
import CheckoutPricePopover from "./CheckoutPricePopover";
import CheckoutSummary from "./CheckoutSummary";

const CheckoutModal = () => {
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const language = useBookingStore((state) => state.language);
  const bookingAmount = useBookingStore(selectBookingAmount);
  const checkoutData = useBookingStore((state) => state.checkoutData);
  const i18n = translate(language);

  return (
    <MobileModal
      isOpen={activeHeaderButton === HeaderButtonCategory.Checkout}
      onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
      align="right"
      size="small"
    >
      <div className={styles.innerModal}>
        <div className={styles.headlineWrapper}>
          <Headline
            as="h1"
            size={2}
            title={i18n.checkout.yourBooking[site.guest_interaction]}
            className={styles.headline}
          />
          <MobileModalHeader
            title={i18n.checkout.yourBooking[site.guest_interaction]}
            onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
          />
        </div>
        <div className={styles.content}>
          <CheckoutSummary modal />
        </div>
        <div className={styles.priceWrapper}>
          <div className={styles.price}>
            <Headline size={1} title={i18n.checkout.total} />
            <Headline size={1} title={formatMoney(bookingAmount, language)} />
          </div>
          <div className={styles.priceInfo}>{i18n.checkout.priceInfo}</div>
          <CheckoutPricePopover prices={checkoutData?.prices} />
        </div>
      </div>
    </MobileModal>
  );
};

export default CheckoutModal;
