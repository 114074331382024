import { useShallow } from "zustand/react/shallow";
import { BookingStep } from "../../@types";
import {
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useAutoOffers } from "../../http/offerApi";
import { useRatePlans } from "../../http/ratePlanApi";
import { translate } from "../../i18n";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import ArrowLeft from "../../ui/icon/arrow-left.svg?react";
import RatePlan from "./RatePlan";
import styles from "./RatePlansAutoOffer.module.css";

const RatePlansAutoOffer = () => {
  const { ratePlansMap } = useRatePlans();
  const { autoOffers } = useAutoOffers();
  const setStep = useBookingStoreSetStep();
  const language = useBookingStore((state) => state.language);
  const autoOfferIndex = useBookingStore((state) => state.autoOfferIndex);
  const currentRoomConfiguration = useBookingStore(
    useShallow(selectCurrentRoomConfiguration),
  );
  const i18n = translate(language);

  if (
    !autoOffers ||
    !ratePlansMap ||
    autoOfferIndex === null ||
    !currentRoomConfiguration
  ) {
    return null;
  }

  const rates =
    autoOffers[autoOfferIndex]?.roomsMap[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ]?.rates;

  return (
    <PageWithHeader
      header={
        <div className={styles.header}>
          <Button
            layout="link"
            buttonProps={{
              onClick: () => setStep(BookingStep.AutoOccupancy),
              title: i18n.ratePlans.back,
              className: styles.backButton,
            }}
          >
            <ArrowLeft className={styles.backButtonIcon} />
            <span className={styles.backButtonTitle}>
              {i18n.ratePlans.back}
            </span>
          </Button>
          <Headline
            className={styles.headerHeadline}
            as="h1"
            size={2}
            title={i18n.ratePlans.selectRate}
          />
        </div>
      }
    >
      <CenterWrapper>
        <div className={styles.ratePlans}>
          {rates?.map((rate, index) => (
            <RatePlan
              key={index}
              prices={rate.prices}
              ratePlan={ratePlansMap[rate.rate_plan_id]}
            />
          ))}
        </div>
      </CenterWrapper>
    </PageWithHeader>
  );
};

export default RatePlansAutoOffer;
